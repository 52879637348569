import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_AssignPhoneNumber_PropertyLocation,
  ApiResponse_AssignPhoneNumber_PropertyLocation,
  useAssignPhoneNumber,
} from '@/api/endpoints/propertyLocation/useAssignPhoneNumber';
import { PropertyLocationModel } from '@/api/models/propertyLocation';
import { formatPhoneNumber } from '@/utils/phone';

import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  phoneNumber: string;
  propertyLocationId: PropertyLocationModel['id'];
  onClose: () => void;
  onPhoneNumberSelected: (propertyLocation: ApiResponse_AssignPhoneNumber_PropertyLocation) => void;
}

const PropertyLocationPhoneNumberAssignModal: React.FC<Props> = ({
  phoneNumber,
  propertyLocationId,
  onClose,
  onPhoneNumberSelected,
}) => {
  const form = useForm<ApiRequest_AssignPhoneNumber_PropertyLocation>({
    defaultValues: {
      phoneNumber,
    },
  });

  const assignPhoneNumberMutation = useAssignPhoneNumber(propertyLocationId, {
    onSuccess: data => {
      onPhoneNumberSelected(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_AssignPhoneNumber_PropertyLocation) =>
      assignPhoneNumberMutation.mutateAsync({
        phoneNumber: data.phoneNumber,
      }),
    [assignPhoneNumberMutation]
  );

  return (
    <ModalWithForm
      onClose={onClose}
      title="Select Phone Number"
      form={form}
      maxWidth="xs"
      onSubmit={onSubmit}
    >
      <Typography variant="body2">You are going to add a phone number to your property.</Typography>

      <Typography variant="body2">
        Selected phone number: <strong>{formatPhoneNumber(phoneNumber)}</strong>.
      </Typography>

      <Typography variant="body2">
        <strong>This action is irreversible.</strong>
      </Typography>
    </ModalWithForm>
  );
};

export default PropertyLocationPhoneNumberAssignModal;
