import { Button, IconButton, Stack } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_Update_Tenant,
  ApiResponse_Update_Tenant,
  useUpdateTenant,
} from '@/api/endpoints/tenant/useUpdateTenant';
import { TenantModelWithContacts } from '@/api/models/tenant';
import { MinusIcon } from '@/assets/icons/MinusIcon';
import { PlusIcon } from '@/assets/icons/PlusIcon';

import FormTextField from '../form/FormTextField';
import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  tenant: TenantModelWithContacts;
  onClose: () => void;
  onTenantUpdated?: (unit: ApiResponse_Update_Tenant) => void;
}

const TenantUpdateModal: React.FC<Props> = ({ tenant, onClose, onTenantUpdated }) => {
  const form = useForm<ApiRequest_Update_Tenant>({
    defaultValues: {
      full_name: tenant.full_name,
      contacts:
        tenant.contacts.length > 0
          ? tenant.contacts.map(contact => ({
            email: contact.email,
            phone: contact.phone,
          }))
          : [
            {
              email: '',
              phone: '',
            },
          ],
    },
  });

  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = form;

  const contacts = watch('contacts');

  const onAddContactRow = () => {
    setValue('contacts', [...contacts, { email: '', phone: '' }]);
  };

  const onRemoveContactRow = (index: number) => {
    setValue(
      'contacts',
      contacts.filter((_, i) => i !== index)
    );
  };

  const updateTenantMutation = useUpdateTenant(tenant.id, {
    onSuccess: data => {
      onTenantUpdated?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_Update_Tenant) =>
      updateTenantMutation.mutateAsync({
        ...data,
        contacts: data.contacts
          .map(contact => ({
            ...(contact.email ? { email: contact.email } : {}),
            ...(contact.phone ? { phone: contact.phone } : {}),
          }))
          .filter(contact => contact.email || contact.phone),
      }),
    [updateTenantMutation]
  );

  return (
    <ModalWithForm
      form={form}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="xs"
      title="Edit Tenant"
      buttonLabel="Update"
    >
      <FormTextField
        label="Full Name"
        fullWidth
        required
        {...register('full_name', {
          required: 'Full name is required',
        })}
        control={control}
        error={!!errors.full_name}
        helperText={errors.full_name?.message}
        size="small"
      />

      <Stack spacing={2}>
        {contacts.map((contact, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Stack key={index} direction="row" spacing={2} alignItems="center">
            <FormTextField
              label={index === 0 ? 'Email' : undefined}
              {...register(`contacts.${index}.email`)}
              control={control}
              error={!!errors.contacts?.[index]?.email}
              helperText={errors.contacts?.[index]?.email?.message}
              size="small"
            />

            <FormTextField
              label={index === 0 ? 'Phone' : undefined}
              {...register(`contacts.${index}.phone`)}
              control={control}
              error={!!errors.contacts?.[index]?.phone}
              helperText={errors.contacts?.[index]?.phone?.message}
              size="small"
            />

            {contacts.length > 1 && (
              <IconButton
                sx={{
                  mt: index === 0 ? 3 : 0,
                }}
                size="small"
                color="error"
                onClick={() => onRemoveContactRow(index)}
              >
                <MinusIcon />
              </IconButton>
            )}
          </Stack>
        ))}

        <Button
          startIcon={<PlusIcon />}
          onClick={onAddContactRow}
          variant="outlined"
          color="secondary"
          size="small"
        >
          Add an additional email or phone number
        </Button>
      </Stack>
    </ModalWithForm>
  );
};

export default TenantUpdateModal;
